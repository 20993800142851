import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	contact: {
		full_name: "",
		room_no: null,
		email: "",
		password: "",
		country_code: "",
		phone: null,
		checkout_date: null,
	},
};

export const contactSlice = createSlice({
	name: "contact",
	initialState,
	reducers: {
		setContactForm: (state, action) => {
			state.contact[action.payload.field] = action.payload.value;
		},

		resetContactForm: (state) => {
			state.contact = initialState.contact;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setContactForm, resetContactForm } = contactSlice.actions;

export default contactSlice.reducer;
