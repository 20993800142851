import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../Components/Landing/Shared/Footer";
import Navbar from "../Components/Landing/Shared/Navbar";
import { ThemeProvider } from "@mui/material/styles";
import { homeTheme } from "../themes/homeTheme";
import { siteTitle } from "../constants/siteTitle";

const HomeLayout = () => {
	useEffect(() => {
		document.title = siteTitle.home;
	}, []);

	return (
		<ThemeProvider theme={homeTheme}>
			<Navbar />
			<Outlet />
			<Footer />
		</ThemeProvider>
	);
};

export default HomeLayout;
