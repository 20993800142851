import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

const initialState = {
	packages: [],
};

export const packageSlice = createSlice({
	name: "packages",
	initialState,
	reducers: {
		setPackages: (state, action) => {
			state.packages = action.payload;
		},

		selectPackage: (state, action) => {
			const newPackages = cloneDeep(action.payload.packages);

			const packages = newPackages.map((item, index) => {
				// if (item.isSelected) {
				// 	item.isSelected = false;
				// 	return item;
				// } else {
				if (index === action.payload.index) {
					item.isSelected = true;
					return item;
				} else {
					item.isSelected = false;
					return item;
				}
				// }
			});
			state.packages = packages;
		},
		resetPackage: (state, action) => {
			const newPackages = cloneDeep(action.payload.packages);

			const packages = newPackages.map((item, index) => {
					item.isSelected = false;
					return item;
			});
			state.packages = packages;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setPackages, selectPackage, resetPackage } = packageSlice.actions;

export default packageSlice.reducer;
