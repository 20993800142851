import { createSlice } from "@reduxjs/toolkit";
import { UserType } from "../../utils/auth.utils";

const initialState = {
	user: {
		full_name: null,
		nick_name: null,
		email: null,
		password: null,
		phone: null,
		user_type: UserType.USER,
		avatar: null,
		id_card: null,
		company_id: null,
		branch_id: null,
		is_active: false,
		is_superuser: false,
		is_verified: false,
	},
	access_token: null,
	token_type: null,
	me: null,
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		updateAuthForm: (state, action) => {
			state.user[action.payload.field] = action.payload.value;
		},

		updateAuthFormAll: (state, action) => {
			state.user = action.payload;
		},

		resetAuthForm: (state) => {
			state.user = initialState;
		},

		setMe: (state, action) => {
			state.me = action.payload;
		},

		setToken: (state, action) => {
			state.access_token = action.payload.access_token;
			state.token_type = action.payload.token_type;
		},
	},
});

// Action creators are generated for each case reducer function
export const { updateAuthForm, updateAuthFormAll, resetAuthForm, setMe, setToken } = authSlice.actions;

export default authSlice.reducer;
