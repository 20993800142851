import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
	typography: {
		fontFamily: ["DM Sans", "sans-serif"].join(","),
	},
	components: {
		// Name of the component
		MuiButton: {
			styleOverrides: {
				// Name of the slot
				root: {
					// Some CSS
					textTransform: "none",
				},
			},
		},
	},
});

theme = responsiveFontSizes(theme);

export const shopTheme = createTheme(theme, {
	palette: {
		primary: {
			main: "#06A4EC",
		},
		text: {
			primary: "#09131f", // title
			secondary: "#343d48", //text, icon
			light: "#999999", // sub texts
		},
	},
});

// background: linear-gradient(25deg, #00a2ea, #85d0f4) !important;
