import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	dialogRoot: {
		"& .MuiPaper-root": {
			margin: 5,
			maxWidth: 950,
		},

		"& .MuiDialogTitle-root": {
			paddingTop: "8px",
			paddingBottom: "8px",
		},
	},

	stepLabel: {
		"& .MuiStepLabel-label": {
			marginTop: "5px !important",
		},
	},

	productAvatar: {
		borderRadius: "0 !important",
		background: "#e2e2e2 !important",
		padding: "10px !important",
		height: "70px !important",
		width: "70px !important",
	},

	tableCell: {
		display: "flex !important",
		gap: 15,
		alignItems: "center",
	},

	productTitle: {
		color: "#333333",
		// maxWidth: "220px !important",
		lineHeight: "16px !important",
	},

	productColor: {
		marginTop: "5px !important",
		fontSize: "14px !important",
		color: "#999999",
	},
	productQuantity: {
		height: 30,
		background: "#EEEEEE",
		borderRadius: 30,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		gap: 1,

		"& .MuiButtonBase-root:hover": {
			background: "transparent !important",
		},
	},

	productRemoveBtn: {
		background: "#EEEEEE !important",
	},

	continueBtn: {
		borderRadius: "10px !important",
		textTransform: "capitalize !important",
		padding: "5px 30px !important",
		boxShadow: "none !important",
	},
}));
