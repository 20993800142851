export const countries = [
	{
		value: "USA",
		label: "USA",
	},
	{
		value: "Bangladesh",
		label: "Bangladesh",
	},
	{
		value: "Japan",
		label: "Japan",
	},
	{
		value: "China",
		label: "China",
	},
];

export const currencies = [
	{
		value: "USD",
		label: "$",
	},
	{
		value: "EUR",
		label: "€",
	},
	{
		value: "BTC",
		label: "฿",
	},
	{
		value: "JPY",
		label: "¥",
	},
];
