import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	company: {
		company_name: "",
		company_address: "",
		country: "USA",
		currency: "USD",
		owner_id: null,
	},
};

export const companySlice = createSlice({
	name: "company",
	initialState,
	reducers: {
		updateCompanyForm: (state, action) => {
			state.company[action.payload.field] = action.payload.value;
		},

		updateCompanyFormFull: (state, action) => {
			state.company = action.payload;
		},

		resetCompanyForm: (state) => {
			state.company = initialState;
		},
	},
});

// Action creators are generated for each case reducer function
export const { updateCompanyForm, updateCompanyFormFull, resetCompanyForm } = companySlice.actions;

export default companySlice.reducer;
