import React, { useEffect, useState } from "react";
import UpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useWindowScroll } from "react-use";
import { Fab } from "@mui/material";

const ScrollToTop = () => {
	const { y: pageYOffset } = useWindowScroll();
	const [visible, setVisible] = useState(false);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	useEffect(() => {
		if (pageYOffset > 400) {
			setVisible(true);
		} else {
			setVisible(false);
		}
	}, [pageYOffset]);

	if (!visible) {
		return false;
	}
	return (
		<Fab
			size="medium"
			onClick={scrollToTop}
			sx={{
				position: "fixed",
				bottom: 16,
				right: 16,
				color: "primary",
				zIndex: 1,
				bgcolor: "#f2f2f2",
				"&:hover": {
					bgcolor: "#e2e2e2",
				},
			}}
			aria-label="Up"
			// color="inherit"
		>
			<UpIcon color="primary" />
		</Fab>
	);
};

export default ScrollToTop;
