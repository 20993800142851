import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	anchorEl: null,
};

export const activitySlice = createSlice({
	name: "activity",
	initialState,
	reducers: {
		activityClickOpen: (state, action) => {
			// event.currentTarget get by action.payload
			state.anchorEl = action.payload;
		},
		activityClose: (state, action) => {
			state.anchorEl = null;
		},
	},
});

export const { activityClickOpen, activityClose } = activitySlice.actions;
export default activitySlice.reducer;
