import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Avatar, Box, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Images from "../../../../constants/themeData";
import { ROUTING_TREE } from "../../../../constants/siteUrls";
import Badge from "@mui/material/Badge";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CssBaseline from "@mui/material/CssBaseline";
import ShoppingCart from "../ShoppingCart";

const useStyles = makeStyles((theme) => ({
	logoAvatar: {
		width: "60px !important",
		height: "auto !important",
		borderRadius: "0 !important",

		[theme.breakpoints.down("sm")]: {
			width: "65px !important",
		},
	},
}));

const ShopNavbar = (props) => {
	const classes = useStyles();

	// Shopping cart dialog
	const [shoppingCartCartDialogOpen, setShoppingCartCartDialogOpen] = React.useState(false);

	const handleShoppingCartDialogOpen = () => {
		setShoppingCartCartDialogOpen(true);
	};

	const handleShoppingCartDialogClose = () => {
		setShoppingCartCartDialogOpen(false);
	};

	return (
		<React.Fragment>
			<CssBaseline />
			<AppBar
				position="fixed"
				sx={{
					bgcolor: "common.white",
					color: "text.secondary",
					boxShadow: "rgb(43 83 135 / 8%) 0px 3px 8px 0px !important",
				}}
			>
				<Container maxWidth="lg">
					<Toolbar>
						<Box sx={{ display: "flex", flexGrow: 1 }}>
							<Link to={ROUTING_TREE.HOME.HOME}>
								<Avatar className={classes.logoAvatar} src={Images.NavLogo} />
							</Link>
						</Box>
						<Box
							onClick={handleShoppingCartDialogOpen}
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								my: 2,
								cursor: "pointer",
							}}
						>
							<Box>
								<Badge badgeContent={4} color="primary">
									<ShoppingCartIcon color="action" fontSize="small" />
								</Badge>
							</Box>
							<Typography variant="body2">Basket</Typography>
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
			<Toolbar />

			{/* shopping cart */}
			<ShoppingCart
				shoppingCartCartDialogOpen={shoppingCartCartDialogOpen}
				handleShoppingCartDialogOpen={handleShoppingCartDialogOpen}
				handleShoppingCartDialogClose={handleShoppingCartDialogClose}
			/>
		</React.Fragment>
	);
};

export default ShopNavbar;
