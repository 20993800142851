import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	anchorEl: null,
};

export const searchSlice = createSlice({
	name: "search",
	initialState,
	reducers: {
		openSearchBox: (state, action) => {
			// event.currentTarget get by action.payload
			state.anchorEl = action.payload;
		},
		closeSearchBox: (state, action) => {
			state.anchorEl = null;
		},
	},
});

export const { openSearchBox, closeSearchBox } = searchSlice.actions;
export default searchSlice.reducer;
