import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	dialogRoot: {
		"& .MuiPaper-root": {
			[theme.breakpoints.up("md")]: {
				minWidth: 650,
			},
			margin: 5,
			maxWidth: 950,
		},
		"& .MuiDialogTitle-root": {
			paddingTop: "8px",
			paddingBottom: "8px",
		},
	},

	stepLabel: {
		"& .MuiStepLabel-label": {
			marginTop: "5px !important",
		},
	},

	orderSummary: {
		display: "flex",
		gap: 1,
		justifyContent: "space-between",
		flexWrap: "wrap",
		background: "#f2f2f2",
		padding: "10px 32px",

		"& button": {
			border: "none",
			background: "transparent",
		},
	},

	orderSummaryExpanded: {
		border: "2px solid #ddd",
		borderRadius: "5px",
	},

	payment: {
		border: "2px solid #ddd",
		borderRadius: "5px",
		// padding: 10,
		background: "#FAFAFA",
	},

	hide: {
		overflow: "hidden",
		height: 0,
		opacity: 0,
		transition: "height 0ms 400ms, opacity 400ms 0ms",
	},
	visible: {
		height: "auto",
		opacity: 1,
		transition: "height 0ms 0ms, opacity 600ms 0ms",
	},

	expandIcon: {
		transform: "rotate(180deg)",
	},

	textField: {
		backgroundColor: "#fff",
		"& input": {
			padding: "8px 10px !important",
		},
		"& .MuiSelect-select": {
			padding: "8px 10px !important",
		},
		"& legend": {
			float: "left !important",
		},
		"& fieldset": {
			top: 0,
		},
	},

	methodField: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		border: "1px solid #ddd",
		padding: "5px 10px",
		borderRadius: 5,
	},

	paymentAvatar: {
		borderRadius: "0 !important",
	},

	paymentTitle: {
		display: "flex",
		justifyContent: "space-between",
		flexWrap: "wrap",
		backgroundColor: "#fff",
		borderBottom: "1px solid #ddd",
		padding: "5px 10px",
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
	},
}));
