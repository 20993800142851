import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
	dialogRoot: {
		"& .MuiPaper-root": {
			maxWidth: 850,
			margin: 5,
		},
	},

	avatarLogo: {
		borderRadius: "0 !important",
		height: "auto !important",
		width: "25% !important",
	},

	textField: {
		"& input": {
			padding: "8px 10px !important",
		},
		"& .MuiSelect-select": {
			padding: "8px 10px !important",
		},
		// "& legend": {
		// 	float: "left !important",
		// },
	},
}));
