import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	alreadyHaveSMTP: false,
	input: {
		mail_host: "",
		mail_port: "",
		mail_username: "",
		mail_password: "",
		mail_from_address: "",
		mail_form_name: "",
		branch_id: "",
	},
};

export const smtpSlice = createSlice({
	name: "smtp",
	initialState,
	reducers: {
		updateSMTPForm: (state, action) => {
			state.input[action.payload.field] = action.payload.value;
		},
		updateAlreadyHaveSMT: (state, action) => {
			state.alreadyHaveSMTP = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { updateSMTPForm, resetSMTPForm, updateAlreadyHaveSMT } = smtpSlice.actions;

export default smtpSlice.reducer;
