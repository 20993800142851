import { createSlice } from "@reduxjs/toolkit";

export const totalSlice = createSlice({
	name: "total",
	initialState: {
		totalCost: 0,
	},
	reducers: {
		setTotalCost: (state, action) => {
			state.totalCost = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setTotalCost } = totalSlice.actions;

export default totalSlice.reducer;
