import * as React from "react";
import {
	Box,
	Divider,
	FormControlLabel,
	Grid,
	IconButton,
	TextField,
	Typography,
	FormControl,
	Radio,
	Avatar,
} from "@mui/material";
import { useStyles } from "./styled";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import InputAdornment from "@mui/material/InputAdornment";
import HelpIcon from "@mui/icons-material/Help";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LockIcon from "@mui/icons-material/Lock";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Images from "../../../../constants/themeData.js";
import { QontoConnector, QontoStepIcon } from "../../../StyledComponent/StepperStyled";
// shopping cart step
const steps = ["Your Cart", "Shipping", "Payment"];

const Payment = ({ paymentDialogOpen, handlePaymentDialogClose, handleShippingDialogOpen }) => {
	const classes = useStyles();
	const [showOrderSummary, setShowOrderSummary] = React.useState(false);

	// handle show order summary
	const handleToggleOrderSummary = () => {
		setShowOrderSummary(!showOrderSummary);
	};

	return (
		<Box component="div">
			<Dialog
				className={classes.dialogRoot}
				open={paymentDialogOpen}
				onClose={handlePaymentDialogClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<Box sx={{ width: "100%" }}>
						<Stepper alternativeLabel activeStep={3} connector={<QontoConnector />}>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel className={classes.stepLabel} StepIconComponent={QontoStepIcon}>
										{label}
									</StepLabel>
								</Step>
							))}
						</Stepper>
					</Box>
					<IconButton
						onClick={handlePaymentDialogClose}
						sx={{
							position: "absolute",
							right: 15,
							top: 8,
						}}
						// color="primary"
						aria-label="open dialog"
						component="span"
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<Divider />
				{/* order summary */}
				<Box className={classes.orderSummary}>
					<Box
						onClick={handleToggleOrderSummary}
						component="button"
						sx={{ display: "flex", gap: 1, mb: { xs: 3, sm: 0 } }}
					>
						<AddShoppingCartIcon color="primary" />
						<Typography color="primary">Show order summary </Typography>
						<KeyboardArrowDownIcon color="primary" className={showOrderSummary && classes.expandIcon} />
					</Box>
					<Typography variant="body1" color="text.primary" sx={{ fontWeight: "medium" }}>
						$10,121.76 USD
					</Typography>
				</Box>
				<DialogContent
					sx={{
						"&::-webkit-scrollbar": {
							width: 8,
						},
						"&::-webkit-scrollbar-track": {
							background: "#fff",
						},
						"&::-webkit-scrollbar-thumb": {
							background: "#e2e2e2",
							borderRadius: 5,
						},
					}}
				>
					<Box>
						{/* order summary */}
						<Box
							mb={2}
							className={`${classes.orderSummaryExpanded} ${
								showOrderSummary ? classes.visible : classes.hide
							}`}
						>
							<TableContainer>
								<Table sx={{ minWidth: 550 }} aria-label="simple table">
									<TableBody>
										{/* contact */}
										<TableRow
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
											}}
										>
											<TableCell component="th" scope="row">
												<Typography color="text.light" sx={{ fontSize: 14 }}>
													Contact
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography sx={{ fontSize: 14 }}>+99 01983763</Typography>
											</TableCell>
											<TableCell align="left">
												<Button variant="text" size="small">
													Change
												</Button>
											</TableCell>
										</TableRow>

										{/* Ship to */}
										<TableRow
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
											}}
										>
											<TableCell component="th" scope="row">
												<Typography color="text.light" sx={{ fontSize: 14 }}>
													Ship to
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Typography sx={{ fontSize: 14 }}>
													+99 01983763, United Kingdom, United States
												</Typography>
											</TableCell>
											<TableCell align="left">
												<Button variant="text" size="small">
													Change
												</Button>
											</TableCell>
										</TableRow>

										{/* Ship to */}
										<TableRow
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
											}}
										>
											<TableCell component="th" scope="row">
												<Typography color="text.light" sx={{ fontSize: 14 }}>
													Method
												</Typography>
											</TableCell>
											<TableCell align="left">
												Standard <strong>$40.76 USD</strong>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Box>

						{/* Payment */}
						<Box component="form" noValidate autoComplete="off">
							<Typography variant="body1" color="text.primary" sx={{ fontWeight: "bold" }}>
								Payment
							</Typography>
							<Typography sx={{ mb: 2 }} variant="body2" color="text.secondary">
								All transactions are secure and encrypted.
							</Typography>

							<Box className={classes.payment}>
								{/* payment card top */}
								<Box className={classes.paymentTitle}>
									<FormControl>
										<FormControlLabel value="standard" control={<Radio />} label="Credit Card" />
									</FormControl>

									<Box item xs={12} sm={6}>
										<Box
											sx={{
												display: "flex",
												gap: 1,
												justifyContent: "end",
											}}
										>
											<Avatar
												className={classes.paymentAvatar}
												src={Images.VisaImg}
												alt="payment card"
											/>

											<Avatar
												className={classes.paymentAvatar}
												src={Images.MaestroImg}
												alt="payment card"
											/>
										</Box>
									</Box>
								</Box>
								<Box p={2}>
									<Box>
										<TextField
											size="small"
											fullWidth
											id="input-with-icon-textfield"
											label="Card number"
											variant="outlined"
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<LockIcon />
													</InputAdornment>
												),
											}}
										/>
									</Box>

									<Box my={2}>
										<TextField
											size="small"
											fullWidth
											id="input-with-icon-textfield"
											label="Name on card"
											variant="outlined"
										/>
									</Box>

									<Grid container spacing={2}>
										<Grid item xs={12} sm={6}>
											<TextField
												size="small"
												fullWidth
												id="input-with-icon-textfield"
												label="Expiration date (MM / YY)"
												variant="outlined"
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												size="small"
												fullWidth
												id="input-with-icon-textfield"
												label="Security code"
												variant="outlined"
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<HelpIcon />
														</InputAdornment>
													),
												}}
											/>
										</Grid>
									</Grid>
								</Box>
							</Box>

							{/* billing address */}
							{/* <Box my={3}>
								<FormControl>
									<FormControlLabel
										value="standard"
										control={<Radio />}
										label="Use a different billing address"
									/>
								</FormControl>
							</Box> */}
						</Box>

						<Box
							my={2}
							sx={{
								display: "flex",
								gap: 1,
								flexWrap: "wrap",
								justifyContent: "space-between",
							}}
						>
							<Box>
								<Button
									onClick={handleShippingDialogOpen}
									variant="text"
									size="small"
									sx={{
										borderRadius: 0,
										textTransform: "capitalize",
									}}
								>
									<ArrowBackIosIcon sx={{ fontSize: 14 }} /> Return to shipping
								</Button>
							</Box>
							<Box>
								<Button variant="contained" size="small" sx={{ boxShadow: 0, borderRadius: 2 }}>
									Pay now
								</Button>
							</Box>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
		</Box>
	);
};

export default Payment;
