import * as React from "react";
import { Avatar, Box, DialogActions, Divider, Grid, IconButton, TextField, Typography } from "@mui/material";
import { useStyles } from "./styled";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Shipping from "../Shipping";
import { QontoConnector, QontoStepIcon } from "../../../StyledComponent/StepperStyled.js";

// shopping cart step
const steps = ["Your Cart", "Shipping", "Payment"];

const ShoppingCart = ({ shoppingCartCartDialogOpen, handleShoppingCartDialogOpen, handleShoppingCartDialogClose }) => {
	const classes = useStyles();

	// shipping details
	const [shippingDialogOpen, setShippingDialogOpen] = React.useState(false);

	const handleShippingDialogOpen = () => {
		handleShoppingCartDialogClose();
		setShippingDialogOpen(true);
	};

	const handleShippingDialogClose = () => {
		setShippingDialogOpen(false);
	};

	return (
		<Box component="div">
			<Dialog
				className={classes.dialogRoot}
				open={shoppingCartCartDialogOpen}
				onClose={handleShoppingCartDialogClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<Box sx={{ width: "100%" }}>
						<Stepper alternativeLabel activeStep={1} connector={<QontoConnector />}>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel className={classes.stepLabel} StepIconComponent={QontoStepIcon}>
										{label}
									</StepLabel>
								</Step>
							))}
						</Stepper>
					</Box>
					<IconButton
						onClick={handleShoppingCartDialogClose}
						sx={{
							position: "absolute",
							right: 15,
							top: 8,
						}}
						// color="primary"
						aria-label="open dialog"
						component="span"
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<Divider />
				<DialogContent
					sx={{
						"&::-webkit-scrollbar": {
							width: 8,
						},
						"&::-webkit-scrollbar-track": {
							background: "#fff",
						},
						"&::-webkit-scrollbar-thumb": {
							background: "#e2e2e2",
							borderRadius: 5,
						},
						padding: 0,
					}}
				>
					<TableContainer>
						<Table sx={{ minWidth: 650 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography
											color="text.light"
											sx={{
												letterSpacing: 1,
												fontSize: 14,
											}}
										>
											PRODUCT
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography
											color="text.light"
											sx={{
												letterSpacing: 1,
												fontSize: 14,
											}}
										>
											PRICE
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography
											color="text.light"
											sx={{
												letterSpacing: 1,
												fontSize: 14,
											}}
										>
											QUANTITY&nbsp;
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography
											color="text.light"
											sx={{
												letterSpacing: 1,
												fontSize: 14,
											}}
										>
											TOTAL&nbsp;
										</Typography>
									</TableCell>
									<TableCell align="center">&nbsp;&nbsp;</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Array.from({ length: 8 }).map((_, idx) => (
									<TableRow
										key={idx}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
										}}
									>
										<TableCell align="left" className={classes.tableCell}>
											<Avatar
												className={classes.productAvatar}
												src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOWQzXJTIHWygwXdQurV3i69zY1T9Wr7Z5oQ&usqp=CAU"
												alt="product"
											/>
											<Box>
												<Typography color="text.primary" className={classes.productTitle}>
													Analog Magazine Rack
												</Typography>
												<Typography className={classes.productColor}>Red</Typography>
											</Box>
										</TableCell>
										<TableCell align="center">
											<Typography color="text.primary">$45</Typography>
										</TableCell>
										<TableCell align="center">
											<Box className={classes.productQuantity}>
												<IconButton sx={{ color: "#333333" }} component="span">
													<RemoveIcon fontSize="small" />
												</IconButton>
												<Typography color="text.primary">2</Typography>
												<IconButton sx={{ color: "#333333" }} component="span">
													<AddIcon fontSize="small" />
												</IconButton>
											</Box>
										</TableCell>
										<TableCell align="center">
											<Typography color="text.primary">$132</Typography>
										</TableCell>
										<TableCell align="right">
											<IconButton
												className={classes.productRemoveBtn}
												sx={{ color: "#333333" }}
												aria-label="delete"
												size="small"
											>
												<CloseIcon fontSize="small" />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</DialogContent>
				<Divider />
				<DialogActions sx={{ p: 2 }}>
					<Grid container justifyContent="space-between">
						<Grid item xs={12} sm={4}>
							<TextField size="small" id="outlined-basic" label="Promo Code" variant="outlined" />
						</Grid>
						<Grid item xs={12} sm={5}>
							<Box mb={2} sx={{ mt: { xs: 1, sm: 0 } }}>
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Typography
										variant="subtitle1"
										color="text.secondary"
										sx={{ fontWeight: "medium" }}
									>
										Subtotal
									</Typography>
									<Typography
										variant="subtitle1"
										color="text.secondary"
										sx={{ fontWeight: "medium" }}
									>
										$418,00
									</Typography>
								</Box>
								<Typography variant="body2" color="text.secondary" sx={{ fontSize: 11 }}>
									Tax included and shipping calculated at checkout
								</Typography>
							</Box>
							<Box textAlign="right">
								<Button
									onClick={handleShippingDialogOpen}
									variant="contained"
									className={classes.continueBtn}
								>
									Continue
								</Button>
							</Box>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>

			{/* shipping dialog */}
			<Shipping
				shippingDialogOpen={shippingDialogOpen}
				handleShippingDialogClose={handleShippingDialogClose}
				handleShoppingCartDialogOpen={handleShoppingCartDialogOpen}
				handleShippingDialogOpen={handleShippingDialogOpen}
			/>
		</Box>
	);
};

export default ShoppingCart;
