import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	anchorEl: null,
};

export const notificationSlice = createSlice({
	name: "notification",
	initialState,
	reducers: {
		notificationClick: (state, action) => {
			// event.currentTarget get by action.payload
			state.anchorEl = action.payload;
		},
		notificationClose: (state, action) => {
			state.anchorEl = null;
		},
	},
});

export const { notificationClick, notificationClose } = notificationSlice.actions;
export default notificationSlice.reducer;
