import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	settings: {
		outlet_status: "open",
		working_hours_start: "10.00",
		working_hours_end: "17.00",
		break_time_start: "13.00",
		break_time_end: "14.00",
		schedule_timing: 2,
		max_booking: 1,
	},
};

export const settingsSlice = createSlice({
	name: "settings",
	initialState,
	reducers: {
		updateSettingsForm: (state, action) => {
			state.settings[action.payload.field] = action.payload.value;
		},

		resetSettingsForm: (state) => {
			state.settings = initialState;
		},
	},
});

// Action creators are generated for each case reducer function
export const { updateSettingsForm, resetSettingsForm } = settingsSlice.actions;

export default settingsSlice.reducer;
