import { emptySplitApi } from "./base";

export const companyApi = emptySplitApi.injectEndpoints({
	endpoints: (build) => ({
		filteredCompanies: build.query({
			query: (params) => ({
				url: "/company/filter",
				params,
			}),
		}),
		getCompany: build.query({
			query: ({ id }) => ({
				url: `/company/${id}`,
			}),
		}),
		getCompanyByUser: build.query({
			query: ({ id }) => ({
				url: `/company/by_user/${id}`,
			}),
		}),
		submitCompany: build.mutation({
			query: (body) => ({
				url: "/company/",
				method: "POST",
				body,
			}),
		}),
		submitCompanyWithUser: build.mutation({
			query: (body) => ({
				url: "/company/with_user",
				method: "POST",
				body,
			}),
		}),
		updateCompany: build.mutation({
			query: ({ id, body }) => ({
				url: `/company/${id}`,
				method: "PUT",
				body,
			}),
		}),
		updateCompanyWithUser: build.mutation({
			query: ({ id, user, company }) => ({
				url: `/company/with_user/${id}`,
				method: "PUT",
				body: { user, company },
			}),
		}),
		deleteCompany: build.mutation({
			query: (id) => ({
				url: `/company/${id}`,
				method: "DELETE",
			}),
		}),
	}),
	overrideExisting: false,
});

export const {
	useFilteredCompaniesQuery,
	useGetCompanyQuery,
	useGetCompanyByUserQuery,
	useSubmitCompanyMutation,
	useSubmitCompanyWithUserMutation,
	useUpdateCompanyMutation,
	useUpdateCompanyWithUserMutation,
	useDeleteCompanyMutation,
} = companyApi;
