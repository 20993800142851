import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";

export const QontoConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 10,
		left: "calc(-50% + 16px)",
		right: "calc(50% + 16px)",
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: "#999999",
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: "#999999",
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#86D0F3",
		borderTopWidth: 3,
		borderRadius: 1,
	},
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
	color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
	display: "flex",
	height: 22,
	alignItems: "center",
	...(ownerState.active && {
		color: "#999999",
		width: 22,
		height: 22,
		display: "flex",
		justifyContent: "center",
		border: "2px solid #1CA5E9",
		borderRadius: "50%",
	}),
	"& .QontoStepIcon-completedIcon": {
		color: "#1CA5E9",
		zIndex: 1,
		fontSize: 24,
	},
	"& .QontoStepIcon-circle": {
		width: 11,
		height: 11,
		borderRadius: "50%",
		backgroundColor: "currentColor",
	},
}));

export function QontoStepIcon(props) {
	const { active, completed, className } = props;

	return (
		<QontoStepIconRoot ownerState={{ active }} className={className}>
			{completed ? (
				<CheckCircleIcon className="QontoStepIcon-completedIcon" />
			) : (
				<div className="QontoStepIcon-circle" />
			)}
		</QontoStepIconRoot>
	);
}
