import * as React from "react";
import {
	Box,
	Divider,
	FormControlLabel,
	Grid,
	IconButton,
	TextField,
	Typography,
	MenuItem,
	FormControl,
	Radio,
} from "@mui/material";
import { useStyles } from "./styled";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import InputAdornment from "@mui/material/InputAdornment";
import HelpIcon from "@mui/icons-material/Help";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Payment from "../Payment";
import { QontoConnector, QontoStepIcon } from "../../../StyledComponent/StepperStyled.js";

// shopping cart step
const steps = ["Your Cart", "Shipping", "Payment"];

const currencies = [
	{
		value: "USD",
		label: "$",
	},
	{
		value: "EUR",
		label: "€",
	},
	{
		value: "BTC",
		label: "฿",
	},
	{
		value: "JPY",
		label: "¥",
	},
];

const Shipping = ({
	shippingDialogOpen,
	handleShippingDialogClose,
	handleShoppingCartDialogOpen,
	handleShippingDialogOpen,
}) => {
	const classes = useStyles();

	const [currency, setCurrency] = React.useState("EUR");

	const handleChange = (event) => {
		setCurrency(event.target.value);
	};

	// payment details
	const [paymentDialogOpen, setPaymentDialogOpen] = React.useState(false);

	const handlePaymentDialogOpen = () => {
		handleShippingDialogClose();
		setPaymentDialogOpen(true);
	};

	const handlePaymentDialogClose = () => {
		setPaymentDialogOpen(false);
	};

	return (
		<Box component="div">
			<Dialog
				className={classes.dialogRoot}
				open={shippingDialogOpen}
				onClose={handleShippingDialogClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<Box sx={{ width: "100%" }}>
						<Stepper alternativeLabel activeStep={2} connector={<QontoConnector />}>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel className={classes.stepLabel} StepIconComponent={QontoStepIcon}>
										{label}
									</StepLabel>
								</Step>
							))}
						</Stepper>
					</Box>
					<IconButton
						onClick={handleShippingDialogClose}
						sx={{
							position: "absolute",
							right: 15,
							top: 8,
						}}
						// color="primary"
						aria-label="open dialog"
						component="span"
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<Divider />
				<DialogContent
					sx={{
						"&::-webkit-scrollbar": {
							width: 8,
						},
						"&::-webkit-scrollbar-track": {
							background: "#fff",
						},
						"&::-webkit-scrollbar-thumb": {
							background: "#e2e2e2",
							borderRadius: 5,
						},
					}}
				>
					<Box>
						<Box>
							<Grid
								container
								spacing={{ xs: 0, md: 3 }}
								justifyContent="space-between"
								alignItems="center"
							>
								<Grid item xs={12} md={6}>
									<Typography variant="body1" color="text.secondary">
										Contact information
									</Typography>
								</Grid>
								<Grid item xs={12} md={6}>
									<Typography variant="body1" color="text.secondary">
										Already have a an account?{" "}
										<Button disableRipple variant="text">
											Log in
										</Button>
									</Typography>
								</Grid>
							</Grid>
						</Box>
						{/* form */}
						<Box my={2}>
							<TextField
								size="small"
								fullWidth
								id="input-with-icon-textfield"
								label="Email"
								variant="outlined"
							/>
						</Box>
						{/* sipping Address */}
						<Box component="form" noValidate autoComplete="off">
							<Typography sx={{ mb: 2 }} variant="body1" color="text.primary">
								Shipping address
							</Typography>

							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<TextField
										size="small"
										fullWidth
										id="input-with-icon-textfield"
										label="First name"
										variant="outlined"
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										size="small"
										fullWidth
										id="input-with-icon-textfield"
										label="Last name"
										variant="outlined"
									/>
								</Grid>
							</Grid>

							<Box my={2}>
								<TextField
									size="small"
									fullWidth
									id="input-with-icon-textfield"
									label="Company (optional)"
									variant="outlined"
								/>
							</Box>

							<Box my={2}>
								<TextField
									size="small"
									fullWidth
									id="input-with-icon-textfield"
									label="Address"
									variant="outlined"
								/>
							</Box>

							<Box my={2}>
								<TextField
									size="small"
									fullWidth
									id="input-with-icon-textfield"
									label="Apartment, suite, etc. (optional)"
									variant="outlined"
								/>
							</Box>

							<Box my={2}>
								<TextField
									size="small"
									fullWidth
									id="input-with-icon-textfield"
									label="City"
									variant="outlined"
								/>
							</Box>

							<Box my={2}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={4}>
										<Typography sx={{ mb: 1 }} variant="body1" color="text.primary">
											Country/Region
										</Typography>
										<TextField
											id="filled-select-currency"
											select
											fullWidth
											// label="Select"
											value={currency}
											placeholder="Country/Region"
											onChange={handleChange}
											variant="outlined"
											className={classes.textField}
										>
											{currencies.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</TextField>
									</Grid>
									<Grid item xs={12} sm={4}>
										<Typography sx={{ mb: 1 }} variant="body1" color="text.primary">
											State
										</Typography>
										<TextField
											id="filled-select-currency"
											select
											fullWidth
											// label="Select"
											value={currency}
											placeholder="Country/Region"
											onChange={handleChange}
											variant="outlined"
											className={classes.textField}
										>
											{currencies.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</TextField>
									</Grid>
									<Grid item xs={12} sm={4}>
										<Typography sx={{ mb: 1 }} variant="body1" color="text.primary">
											&nbsp;
										</Typography>
										<TextField
											className={classes.textField}
											fullWidth
											id="input-with-icon-textfield"
											placeholder="Zip code"
											variant="outlined"
										/>
									</Grid>
								</Grid>

								<Box my={2}>
									<TextField
										size="small"
										fullWidth
										id="input-with-icon-textfield"
										label="Phone"
										variant="outlined"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<HelpIcon />
												</InputAdornment>
											),
										}}
									/>
								</Box>
							</Box>

							{/* shipping method */}
							<Box my={3}>
								<Typography sx={{ mb: 1 }} variant="body1" color="text.primary">
									Shipping Method
								</Typography>
								<Box className={classes.methodField}>
									<FormControl>
										<FormControlLabel value="standard" control={<Radio />} label="Standard" />
									</FormControl>
									<Typography sx={{ fontWeight: "medium" }} variant="body1" color="text.primary">
										$40.76 USD
									</Typography>
								</Box>
							</Box>
						</Box>

						<Box
							my={2}
							sx={{
								display: "flex",
								gap: 1,
								flexWrap: "wrap",
								justifyContent: "space-between",
							}}
						>
							<Box>
								<Button
									onClick={handleShoppingCartDialogOpen}
									variant="text"
									size="small"
									sx={{ borderRadius: 0, textTransform: "capitalize" }}
								>
									<ArrowBackIosIcon sx={{ fontSize: 14 }} /> Return to cart
								</Button>
							</Box>
							<Box>
								<Button
									onClick={handlePaymentDialogOpen}
									variant="contained"
									size="small"
									sx={{ boxShadow: 0, borderRadius: 2 }}
								>
									Continue to Payment
								</Button>
							</Box>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>

			{/* payment Dialog */}
			<Payment
				paymentDialogOpen={paymentDialogOpen}
				handlePaymentDialogClose={handlePaymentDialogClose}
				handleShippingDialogOpen={handleShippingDialogOpen}
			/>
		</Box>
	);
};

export default Shipping;
