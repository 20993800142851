import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	dialogRoot: {
		"& .MuiPaper-root": {
			[theme.breakpoints.up("md")]: {
				minWidth: 650,
			},
			margin: 5,
			maxWidth: 950,
		},
		"& .MuiDialogTitle-root": {
			paddingTop: "8px",
			paddingBottom: "8px",
		},
	},

	stepLabel: {
		"& .MuiStepLabel-label": {
			marginTop: "5px !important",
		},
	},

	textField: {
		"& input": {
			padding: "8px 10px !important",
		},
		"& .MuiSelect-select": {
			padding: "8px 10px !important",
		},
		"& legend": {
			float: "left !important",
		},
	},

	methodField: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		border: "1px solid #ddd",
		padding: "5px 10px",
		borderRadius: 5,
	},

	continueBtn: {
		borderRadius: "10px !important",
		textTransform: "capitalize !important",
		padding: "5px 30px !important",
		boxShadow: "none !important",
	},
}));
