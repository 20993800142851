import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	appBarWhite: {
		background: "#fff !important",
		boxShadow: "rgb(43 83 135 / 8%) 0px 3px 8px 0px !important",
		transition: "all .35s ease-in-out 0s !important",
	},
	appBarTransparent: {
		background: "transparent !important",
		padding: "15px 0",
		transition: "all .35s ease-in-out 0s !important",
	},

	dropdown: {
		position: "relative !important",

		"& > a": {
			textDecoration: "none !important",
		},

		"& .MuiTypography-root": {
			color: "#333333 !important",
			textTransform: "capitalize !important",
		},

		"& .MuiPaper-root": {
			position: "absolute",
			minWidth: "230px !important",
			top: "2.3rem",
			left: "0",
			opacity: 1,
			transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
			transformOrigin: "0px 0px",
			display: "none",

			"& .MuiList-root": {
				padding: "0 !important",

				"& .MuiListItem-root": {
					minHeight: "auto !important",

					"&:hover": {
						backgroundColor: "#F2F2F2",
					},
					"& .MuiListItemText-root": {},
				},
			},
		},

		"&:hover": {
			"& .MuiPaper-root": {
				display: "block",
			},
		},
	},
	enDropdown: {
		position: "relative !important",

		"& .MuiTypography-root": {
			color: "#333333 !important",
			textTransform: "capitalize !important",
		},

		"& .MuiPaper-root": {
			position: "absolute",
			minWidth: "230px !important",
			top: "2.3rem",
			right: "0",
			opacity: 1,
			transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
			transformOrigin: "0px 0px",
			display: "none",

			"& .MuiList-root": {
				padding: "0 !important",

				"& .MuiListItem-root": {
					minHeight: "auto !important",

					"&:hover": {
						backgroundColor: "#F2F2F2",
					},
					"& .MuiListItemText-root": {},
				},
			},
		},

		"&:hover": {
			"& .MuiPaper-root": {
				display: "block",
			},
		},
	},

	logoAvatar: {
		marginLeft: "-1rem",
		width: "80px !important",
		height: "auto !important",
		borderRadius: "0 !important",

		[theme.breakpoints.down("md")]: {
			width: "60px !important",
		},
	},

	link: {
		textDecoration: "none !important",
		textTransform: "capitalize !important",
		color: "#333333",
		"&:hover": {
			color: "#999999",
		},
	},

	navBtn: {
		fontWeight: "bold !important",
		margin: "2px 8px !important",
		padding: "5px !important",
		textTransform: "capitalize !important",
		borderRadius: "30px !important",

		"&:hover": {
			background: "hsla(0,0%,98%,.15) !important",
		},

		"& > a": {
			textDecoration: "none !important",
		},
	},

	// mobile menu

	mobileMenu: {
		width: "100%",
		position: "absolute",
		top: "65px",
		left: "-200%",
		opacity: 1,
		transition: "all 0.5s ease",
	},
	mobileMenu2: {
		width: "100%",
		background: "#242222",
		position: "absolute",
		top: "65px",
		left: "0",
		opacity: 1,
		transition: "all 0.5s ease",
	},

	mobileMenuList: {
		"& li": {
			cursor: "pointer",
			"&:hover": {
				color: "#999999",
			},
			paddingTop: 0,
			paddingBottom: 0,
			margin: "8px 0",

			"&:last-child": {
				borderBottom: "none",
			},
		},

		"& .MuiTypography-root": {
			fontSize: "1.2rem",
			transition: "all .3s ease-in-out",

			[theme.breakpoints.down("sm")]: {
				fontSize: "1.1rem",
			},
		},
	},

	// Expand menu
	expandMenu: {
		minWidth: "150px !important",
		"& .MuiPaper-root": {
			boxShadow: "none",
			backgroundColor: "transparent",

			"&::before": {
				background: "transparent",
			},

			"& .MuiButtonBase-root": {
				padding: "0 !important",
				minHeight: 0,
			},
		},

		"& .MuiAccordionDetails-root": {
			padding: "0 !important",
			"& ul": {
				marginTop: "-1.3rem",
				padding: "0 !important",
			},
			"& li": {
				cursor: "pointer",
				color: "#333333",

				"&:hover": {
					color: "#999999",
				},
			},
		},

		"& .MuiAccordionSummary-content": {
			margin: 0,
		},
	},

	divider: {
		backgroundColor: "#ddd",
	},
	enDropdownWrap: {
		"& li": {
			"&:hover": {
				background: "transparent !important",
			},
		},
	},
}));
