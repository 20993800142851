import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
	list: {
		"& .MuiListItem-root": {
			padding: 0,
			color: "#5f6368",
			cursor: "pointer",
		},
		"& a": {
			textDecoration: "none !important",
			// display: "inline-block",

			"& .MuiTypography-root": {
				color: "#5f6368",
			},
		},
	},

	avatar: {
		width: "40px % !important",
		height: "auto % !important",
		borderRadius: "0 !important",
	},

	link: {
		display: "inline-block",
	},
}));
