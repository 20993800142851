import { Outlet } from "react-router-dom";
import Footer from "../Components/Landing/Shared/Footer";
import ShopNavbar from "../Components/Landing/Shop/ShopNavbar";
import { ThemeProvider } from "@mui/material/styles";
import { shopTheme } from "../themes/shopTheme";
import { useEffect } from "react";
import { siteTitle } from "../constants/siteTitle";

const ShopLayout = () => {
	useEffect(() => {
		document.title = siteTitle.shop;
	}, []);

	return (
		<ThemeProvider theme={shopTheme}>
			<ShopNavbar />
			<Outlet />
			<Footer />
		</ThemeProvider>
	);
};

export default ShopLayout;
