import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
	dialogRoot: {
		"& .MuiPaper-root": {
			margin: 5,
			maxWidth: 750,
		},
	},
}));
