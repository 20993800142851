import * as React from "react";
import { Box, DialogContentText, Divider, IconButton, Typography } from "@mui/material";
import { useStyles } from "../styled";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";

const PrivacyPolicy = ({ dialogOpen, handleDialogClose }) => {
	const classes = useStyles();

	return (
		<Box component="div">
			<Dialog
				className={classes.dialogRoot}
				open={dialogOpen}
				onClose={handleDialogClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle
					id="alert-dialog-title"
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography></Typography>
					<Typography variant="h5" color="text.primary" sx={{ fontWeight: "bold" }}>
						Privacy Policy
					</Typography>
					<IconButton onClick={handleDialogClose} aria-label="open dialog" component="span">
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<Divider />
				<DialogContent
					sx={{
						"&::-webkit-scrollbar": {
							width: 8,
						},
						"&::-webkit-scrollbar-track": {
							background: "#fff",
						},
						"&::-webkit-scrollbar-thumb": {
							background: "#e2e2e2",
							borderRadius: 5,
						},
					}}
				>
					<DialogContentText id="alert-dialog-description">
						<Typography sx={{ fontWeight: "bold", my: 1 }} color="text.primary">
							Holipic is the only company to build Automated Cameras with AI technology based photo
							capture systems using a mini computer also which comes with a 3.5-inch touch screen to
							easily connect cameras to in-store app like plug & play. 
						</Typography>
						{/*  */}
						<Typography color="text.primary" sx={{ my: 1 }}>
							Our automated camera provides professional quality photos that guests simply can't resist.
							Nothing is more valuable to your adventure park guests than action and portrait photos that
							they can share with their family and friends, which allows their story, along with your
							brand, to live on. Also In-Store ‘Face Recognition’ is an option that seamlessly matches
							guests to the correct photos and allows guests to instantly access their gallery of photos.
						</Typography>
						{/*  */}
						<Typography color="text.primary" sx={{ my: 1 }}>
							Holipic systems yield the highest sales compared to any other platform available. Our
							sophisticated delivery systems mean that professional photos are sorted, packaged and
							awaiting your guests when they finish their tour, making their decision to purchase is easy.
						</Typography>
						{/*  */}
						<Typography color="text.primary" sx={{ my: 1 }}>
							Automatic cameras available to order from your admin and in our shop as much as you want and
							here are some benefits:
						</Typography>
						{/*  */}
						<Typography color="text.primary" sx={{ my: 1 }}>
							<strong>Make it easy, limitless photo revenue. </strong> <br /> Holipic provides a myriad of
							solutions for the cruise, water park, and theme park industries. Incorporates superior
							cameras, infinite scalability, and the ability to place cameras in any environment
							imaginable. 
						</Typography>
						{/*  */}
						<Typography color="text.primary" sx={{ my: 1 }}>
							Whether it’s impossible vantage points taken from the highest of trees, or using top of the
							line DSLR cameras, Holipic systems makes those photos incredible, seamless, and instantly
							available to your customers, bringing photo revenue back into one of your best revenue
							streams.  The result is limitless photo revenue potential.
						</Typography>
						{/*  */}
						<Typography color="text.primary" sx={{ my: 1 }}>
							<strong>Seamless viewing and fulfillment means customers buy faster and buy more.</strong>
							<br />
							Our Face Recognition technology automatically sorts photos for each patron to find images
							faster and the fulfillment system means that photos are already packaged and awaiting your
							guests right after they are taken, so the decision to view and purchase is easy.
						</Typography>
						{/*  */}
						<Typography color="text.primary" sx={{ my: 1 }}>
							A smooth viewing and purchasing platform using our in-store desktop app, combined with your
							custom package options, not only makes the consideration process effortless for your
							customers, but also enables more money to be spent per customer. 
						</Typography>
						{/*  */}
						<Typography color="text.primary" sx={{ my: 1 }}>
							<strong>Additionally, capturing every moment. Building value for your customers.</strong>
							<br />
							In addition to automated action cameras, Holipic provides a ‘Photographer Mobile App’ to
							make sure your guests can take home every memory from your photographers as well. Creating
							photo packages that capture each guest's experience in every aspect is the key to building
							value for your customers, and building revenue for your business.
						</Typography>
						{/*  */}
						<Typography color="text.primary" sx={{ my: 1 }}>
							<strong>
								Holipic Automated Camera & Apps technology is designed to minimize the costs.
							</strong>
							<br />
							Holipic Apps creates the images that are an integral part of your customers' experience, and
							our systems yield higher sales than any other platform available, period. And since our
							entire process can be hosted in the cloud or onsite, the associated costs compared to
							traditional tourism photography products are zero.
						</Typography>
						{/*  */}
						<Typography color="text.primary" sx={{ my: 1 }}>
							However, our automatic cameras will save a lot of staff cost from regular line shooting
							jobs. Companies will be able to use photographers for valuable shootings with your customers
							directly.
						</Typography>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</Box>
	);
};

export default PrivacyPolicy;
